import {
	fetchBaseQuery,
	BaseQueryFn,
	FetchArgs,
	FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from './index';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { setRapidstorToken } from './authSlice';

const RAPIDSTOR_API_BASE =
	process.env.NEXT_PUBLIC_RAPIDSTOR_API_BASE ||
	'https://prod.rapidstorapp.com/api/v1';

type TokenSelector = (state: RootState) => string | null;
type TokenSetter = ActionCreatorWithPayload<string | null>;

export const generateRapidstorQuery = (
	baseUrl: string,
	apiKey: string,
	selectToken: TokenSelector,
	setToken: TokenSetter
) => {
	const baseQuery = fetchBaseQuery({
		baseUrl: baseUrl,
		prepareHeaders: (headers, { getState }) => {
			const token = selectToken(getState() as RootState);
			if (token) {
				headers.set('Authorization', `Bearer ${token}`);
			}
			return headers;
		},
	});

	const authenticateQuery: FetchArgs = {
		url: 'authenticate',
		method: 'POST',
		body: {
			token: apiKey,
		},
		responseHandler: 'text',
	};

	const rapidstorFetchBase: BaseQueryFn<
		string | FetchArgs,
		unknown,
		FetchBaseQueryError
	> = async (args, api, extraOptions) => {
		let token = selectToken(api.getState() as RootState);

		// If there's no token, authenticate first
		if (!token) {
			const authResp = await baseQuery(authenticateQuery, api, extraOptions);

			if (authResp.data) {
				token = authResp.data as string;
				api.dispatch(setToken(token));
			} else {
				// Authentication failed, return error
				return authResp;
			}
		}

		// Function to retry request with a new token
		const attemptRequest = async (retryCount = 0): Promise<any> => {
			const argHeaders = typeof args === 'object' && args.headers ? args.headers : {};
			const query = await baseQuery(
				typeof args === 'object' ? { ...args, headers: { ...argHeaders, Authorization: `Bearer ${token}` } } : args,
				api,
				extraOptions
			);

			if (
				query.error &&
				(query.error.status === 403 || query.error.status === 401) &&
				(query.error.data as any)?.error === 'Invalid Token'
			) {
				if (retryCount >= 5) {
					console.error("Max retries reached, logging out");
					return query;
				}

				// Refresh token
				const authResp = await baseQuery(authenticateQuery, api, extraOptions);
				if (authResp.data) {
					token = authResp.data as string;
					api.dispatch(setToken(token));

					// Retry original request with the new token
					return attemptRequest(retryCount + 1);
				} else {
					console.error("Token refresh failed");
					return query;
				}
			}

			return query;
		};

		// Execute request attempt
		return attemptRequest();
	};

	return rapidstorFetchBase;
};

export const rapidstorBaseQuery = generateRapidstorQuery(
	RAPIDSTOR_API_BASE,
	process.env.NEXT_PUBLIC_RAPIDSTOR_API_KEY as string,
	(state: RootState) => state.auth.tokens.rapidstor,
	setRapidstorToken
);
